@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Radio+Canada&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tapestry&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  height:auto;
  pointer-events:none
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.vh-full {
  height:100vh !important;
  position: relative !important;
  overflow: hidden !important;
  object-fit: cover !important;
}
.vh-full_2 {
  height:80vh !important;
  position: relative !important;
  overflow: hidden !important;
  object-fit: cover !important;
}

.vh-full_mobile {
  height:50vh !important;
  position: relative !important;
  overflow: hidden !important;
  object-fit: cover !important;
}

.examples {

  transition-timing-function: linear;
  transition-timing-function: cubic-bezier(0, 0, 1, 1);

  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

  transition-timing-function: ease-in-out;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);

  transition-timing-function: ease-in;
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);

  transition-timing-function: ease-out;
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);

}

.navbar {
  transition: background-color 0.5s ease;
}

.white-bg {
  background-color: white;
}

.custom-opacity {
  background-color: rgba(0, 0, 0, 0.5);
}

.animate__animated.animate__fadeInUp {
  animation-duration: 1s;
}
.animated-text {
  animation-name: fade-in;
  animation-duration: 1s;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}